import React, { FC } from 'react'
import ecrBadge from '../../images/ecrBadge.png'

const ECRBadge: FC = () => {
  return (
    <div>
      <img
        src={ecrBadge}
        alt="Excellence in customer relations 2023-2024"
        style={{ width: 'auto', height: '80px' }}
      />
    </div>
  )
}

export default ECRBadge
