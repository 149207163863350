import React, { FC } from 'react'
import ucSigilNordic from '../../images/uc-sigil-nordisk-tillvaxt.png'

const UCSigilNordic: FC = () => {
  return (
    <div>
      <img
        src={ucSigilNordic}
        alt="Nordiskt tillväxtcertifikat"
        style={{ width: 'auto', height: '80px' }}
      />
    </div>
  )
}

export default UCSigilNordic
